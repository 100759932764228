<template>
    <div>
        <v-layout row wrap text-xs-center>
            <v-flex xs12>
                <h2 class="font-weight-bold text-xs-left"><u>Loan: {{loan.name}}</u></h2>
            </v-flex>

            <v-flex xs12 class=" text-xs-right" v-show="loan.status == 'Ongoing'">
                <v-btn small class="animate__animated animate__flash animate__delay-1s elevation-6 white--text ma-0 text-xs-center" round color="teal darken-2 " :to="'/savings/loans/save/'+loan.id" >
                    <v-icon left small class="fas fa-piggy-bank" > </v-icon>
                    Save Now
                </v-btn>
            </v-flex>
        </v-layout>

        <v-card class="primary darken-2 mt-1" >
            <v-card-text v-if="loan.target_amount && loan.target_date" class="pa-2" style="font-size: 12px; ">
                <span class="white--text text-xs-left subheading"><b>Amount Saved:</b> 
                    <span class="pa-0 ma-1 dark--text text-xs-left body-2"> {{loan.saved_amount}} FCFA in {{loan.passed_days}} days</span>
                </span>
            </v-card-text>
            <v-card-text v-else-if="loan.target_amount" class="pa-2" style="font-size: 12px; ">
                <span class="white--text text-xs-left subheading"><b>Amount Saved:</b> 
                    <span class="pa-0 ma-1 dark--text text-xs-left body-2"> {{loan.saved_amount}} FCFA </span>
                </span>
            </v-card-text>
            <v-card-text v-else-if="loan.target_date" class="pa-2" style="font-size: 12px; ">
                <span class="white--text text-xs-left subheading"><b>Amount Saved:</b> 
                    <span class="pa-0 ma-1 dark--text text-xs-left body-2"> {{loan.saved_amount}} FCFA </span>
                </span>
            </v-card-text>
        </v-card>
        
        <v-card class="white elevation-6 "  >
            <v-card-text class="body-2 pa-2" style="font-size: 12px; padding: 5px">
                <span><b>Creation Date:</b> <span class="caption"> {{loan.get_date_str}}</span></span>
                <v-spacer></v-spacer>
                <div v-if="loan.target_amount && loan.target_date" >
                    <span><b>Objective:</b> <span class="caption"> Reach {{loan.target_amount}} FCFA By the {{loan.target_date}} </span> </span>
                </div>
                <div v-else-if="loan.target_amount" >
                    <span><b>Objective:</b> <span class="caption"> Reach {{loan.target_amount}} FCFA </span> </span>
                </div>
                <div v-else-if="loan.target_date" >
                    <span><b>Objective:</b> <span class="caption"> On the {{loan.target_date}} </span> </span>
                    <v-spacer></v-spacer>
                    <span><b>Remaining:</b> <span class="caption"> {{loan.remaining_days}} days</span></span>
                </div>
                <v-spacer></v-spacer>
                <span><b>Percentage Complete: </b> <span class="caption"> {{loan.percentage_completed}}</span></span>
                <v-spacer></v-spacer>
                <span><b>Description: </b> <span class="caption"> {{loan.description}}</span></span>
                <v-spacer></v-spacer>
                <span v-if="loan.cancelled"><b>State: </b> <span class="caption red--text"> Cancelled</span></span>
                <span v-else-if="loan.validated"><b>State: </b> <span class="caption green--text"> Validated</span></span>
                <span v-else-if="!loan.validated"><b>State: </b> <span class="caption primary--text"> Inactive</span></span>
            </v-card-text>
        </v-card>

        <h2 v-if="loanRequestedTransactions.length !== 0" class="mt-3 dark--text font-weight-bold text-xs-left"><u>Requested Transactions</u></h2>
        <div v-if="loanRequestedTransactions.length !== 0" class="white elevation-6 pa-2" style="max-height: 100px; overflow: auto; border-radius: 7px"> 
            <div color="white" style="" v-for="item in loanRequestedTransactions" :key="item.id">
                <v-card-text class="pa-1" style="font-size: 12px;">
                    <v-layout row wrap v-if="!item.cancelled || item.withdrawal || (!item.withdrawal && !item.confirmed)">
                        <v-flex xs8 text-xs-left>
                            <div v-if="item.withdrawal" > <!-- this will be an interrupt-->
                                <span class="red--text font-weight-bold">Interrupt:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-else>
                                <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-if="!item.confirmed && !item.cancelled" xs8 text-xs-left>
                                <span class="font-weight-bold ">Status:</span><span class="primary--text font-weight-bold "> Pending </span>
                            </div>
                            <div v-if="item.confirmed" xs8 text-xs-left>
                                <span class="font-weight-bold ">Status:</span><span class="success--text font-weight-bold "> Confirmed </span>
                            </div>
                            <!-- <div v-if="item.cancelled" xs8 text-xs-left>
                                <span class="font-weight-bold ">Status:</span><span class="red--text font-weight-bold "> Cancelled </span>
                            </div> -->
                        </v-flex>
                        <v-flex xs4 text-xs-right>
                            <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_date_str}}</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            <v-divider></v-divider>
            </div>
        </div>

        <h2 class="mt-3 dark--text font-weight-bold text-xs-left"><u>Validated Transactions</u></h2>
        <div class="white elevation-6 pa-2" style="max-height: 300px; overflow: auto; border-radius: 7px"> 
            <div color="white" style="" v-for="item in loanTransactions" :key="item.id">
                <v-card-text class="pa-1" style="font-size: 12px;">
                    <v-layout row wrap>
                        <v-flex xs6 text-xs-left>
                            <div v-if="item.withdrawal" >
                                <span class="dark--text font-weight-bold">Withdrawal:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-else>
                                <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                            </div>
                        </v-flex>
                        <v-flex xs6 text-xs-right>
                            <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_day_month_year_str}}</span>
                        </v-flex>
                        <v-flex xs8 text-xs-left>
                            <span class="dark--text font-weight-bold ">By:</span><span> {{item.top_up_admin_username}} - {{item.top_up_admin_phone_number}}</span>
                        </v-flex>
                        <v-flex xs4 text-xs-right>
                            <span class="dark--text font-weight-bold ">at</span><span> {{item.transaction_time}}</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            <v-divider></v-divider>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                loan: [],
                loanTransactions: [],
                loanRequestedTransactions: [],
                interruptDialog: false,
                payment_ref: '',
                interrupt_fees: '',
            }
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getLoanDetail();
            this.getLoanTransactions();
            this.getLoanRequestedTransactions();
            document.title = "PettyCash | Loan Detail Info."
            this.$store.commit('setCloseURL', "/savings/loans/active")
        },

        methods:{
            async getLoanInterruptPaymentRef(){
                this.$store.commit('setIsLoading', true)
                
                const loan_id = this.$route.params.loan_id  // get loan category from url and send request to server
                await axios
                    .get('/api/v1/savings/create/user/interrupt/loan/payment_ref/'+loan_id+'/')
                    .then(response => {
                        this.payment_ref = response.data['payment_ref']
                        this.interrupt_fees = response.data['interrupt_fees']
                        if (!response.data["status"]){
                            this.interruptDialog = false
                            this.$store.commit('setSnackBarMessage', "Interrupt Request Already Exist, The request is being processed.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async setInterruptLoan(){
                this.$store.commit('setIsLoading', true)
                
                const loan_id = this.$route.params.loan_id  // get loan category from url and send request to server
                let formData = new FormData()
                formData.append('payment_ref', this.payment_ref)
                await axios
                    .post('/api/v1/savings/interrupt/user/loan/'+loan_id+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        this.interruptDialog = false
                        this.$store.commit('setSnackBarMessage', response.data[0].text)
                        this.$store.commit('setSnackBarColor', response.data[0].color)
                        this.$store.commit('activateSnackBar', true)
                        var navigation = this.$router
                        var store = this.$store  // timers are noted for this
                        if (response.data[0].valid){
                            setTimeout(function(){
                                navigation.go()  // redirect back to loan
                                store.commit('setSnackBarTimeOut', 3000)  // set time out for snack bar to 4 seconds and remember too reset after 
                            }, 3000)
                        }
                    })
                    .catch(error => {
                        this.interruptDialog = false
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getLoanDetail(){
                this.$store.commit('setIsLoading', true)
                
                const loan_id = this.$route.params.loan_id  // get loan category from url and send request to server
                await axios
                    .get('/api/v1/savings/get/user/loan/detail/'+loan_id+'/')
                    .then(response => {
                        this.loan = response.data  // get the data and fill into loans
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getLoanTransactions(){
                this.$store.commit('setIsLoading', true)
                
                const loan_id = this.$route.params.loan_id  // get loan category from url and send request to server
                await axios
                    .get('/api/v1/savings/get/user/loan/'+loan_id+'/transactions/')
                    .then(response => {
                        this.loanTransactions = response.data  // get the data and fill into loans
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            async getLoanRequestedTransactions(){
                this.$store.commit('setIsLoading', true)
                
                const loan_id = this.$route.params.loan_id  // get loan category from url and send request to server
                await axios
                    .get('/api/v1/savings/get/user/loan/'+loan_id+'/requested_transactions/')
                    .then(response => {
                        this.loanRequestedTransactions = response.data  // get the data and fill into loans
                        console.log(this.loanRequestedTransactions)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
        },

    }
</script>
